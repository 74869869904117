import React from "react";
import PropTypes from "prop-types";
import { FormErrors } from "./FormErrors";
import update from "immutability-helper";
import { validations } from "../utils/validations";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { withRouter } from "react-router-dom";
import moment from "moment";

export default class SurveyForm extends React.Component {
  static propTypes = {
    handleNewSurvey: PropTypes.func,
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      stage: "",
      sent_gcp: { value: "", valid: false },
      control_lidar_flight: "No",
      client_name: { value: "", valid: true },
      due_date: { value: "", valid: true },
      client_number: { value: "", valid: true },
      job_number: { value: "", valid: true },
      job_name: { value: "", valid: false },
      project_location: { value: "", valid: false },
      est_flight_date: { value: "", valid: false },
      project_id: { value: "", valid: false },
      survey_staff: { value: "", valid: false },
      abgps: "",
      needs_shifted: "",
      known_system: { value: "", valid: true },
      control_in: { value: "", valid: true },
      control_status: "",
      results: "",
      coordinate_system: { value: "", valid: true },
      survey_done: "",
      project_notes: { value: "", valid: true },
      survey_notes: { value: "", valid: true },
      survey_mapping_complete: "",
      formErrors: {},
      formValid: false,
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    known_system: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    survey_staff: [
      (s) => {
        return validations.checkMinLength(s, 3);
      },
    ],
    control_in: [
      (s) => {
        return validations.checkMinLength(s, 0);
      },
    ],
    sent_gcp: [
      (s) => {
        return validations.checkMinLength(s, 0);
      },
    ],
    coordinate_system: [
      (s) => {
        return validations.checkMinLength(s, 3);
      },
    ],
    project_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    survey_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/surveys/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          stage: data.project.stage,
          project_type: data.project.project_type,
          sent_gcp: { value: data.survey.sent_gcp, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          project_manager: data.project.project_manager,
          deliverables: data.project.deliverables,
          due_date: { value: data.project.due_date, valid: true },
          client_number: { value: data.project.client_number, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          job_name: { value: data.project.job_name, valid: true },
          project_location: {
            value: data.project.project_location,
            valid: true,
          },
          est_flight_date: { value: data.project.est_flight_date, valid: true },
          project_id: { value: data.survey.project_id, valid: true },
          survey_staff: { value: data.survey.survey_staff, valid: true },
          control_lidar_flight: data.survey.control_lidar_flight,
          abgps: data.project.abgps,
          needs_shifted: data.survey.needs_shifted,
          known_system: { value: data.survey.known_system, valid: true },
          control_in: { value: data.project.control_in, valid: true },
          control_status: data.project.control_status,
          results: data.survey.results,
          coordinate_system: {
            value: data.survey.coordinate_system,
            valid: true,
          },
          survey_done: data.survey.survey_done,
          project_notes: { value: data.project.project_notes, valid: true },
          survey_notes: { value: data.project.survey_notes, valid: true },
          survey_mapping_complete: data.survey.survey_mapping_complete,
          editing: this.props.match.path === "/cascopt/surveys/:id/edit",
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName], {
      value: { $set: fieldValue },
    });
    this.setState({ [fieldName]: newFieldState }, () => {
      this.validateField(fieldName, fieldValue, validations);
    });
  };

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== "") {
        errors.push(e);
      }
      return errors;
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName], {
      valid: { $set: fieldValid },
    });

    const newFormErrors = update(this.state.formErrors, {
      $merge: { [fieldName]: fieldErrors },
    });

    this.setState(
      {
        [fieldName]: newFieldState,
        formErrors: newFormErrors,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.project_id.valid &&
        this.state.job_name.valid &&
        this.state.project_location.valid &&
        this.state.survey_staff.valid &&
        this.state.control_in.valid &&
        this.state.sent_gcp.valid &&
        this.state.coordinate_system.valid &&
        this.state.project_notes.valid &&
        this.state.known_system.valid &&
        this.state.survey_notes.valid,
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateSurvey() : this.addSurvey();
  };

  updateSurvey() {
    const survey = {
      sent_gcp: this.state.sent_gcp.value,
      project_id: this.state.project_id.value,
      survey_staff: this.state.survey_staff.value,
      abgps: this.state.abgps,
      needs_shifted: this.state.needs_shifted,
      known_system: this.state.known_system.value,
      control_in: this.state.control_in.value,
      control_status: this.state.control_status,
      results: this.state.results,
      coordinate_system: this.state.coordinate_system.value,
      survey_done: this.state.survey_done,
      project_notes: this.state.project_notes.value,
      survey_notes: this.state.survey_notes.value,
      control_lidar_flight: this.state.control_lidar_flight,
      survey_mapping_complete: this.state.survey_mapping_complete,
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/surveys/${this.props.match.params.id}`,
      data: { survey: survey },
    })
      .done((data) => {
        console.log("survey updated!");
        this.resetFormErrors();
        this.props.history.goBack("/cascopt/surveys");
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false,
        });
      });
  }

  addSurvey() {
    const survey = {
      sent_gcp: this.state.sent_gcp.value,
      job_name: this.state.job_name.value,
      project_location: this.state.project_location.value,
      project_id: this.state.project_id.value,
      survey_staff: this.state.survey_staff.value,
      abgps: this.state.abgps,
      needs_shifted: this.state.needs_shifted,
      known_system: this.state.known_system.value,
      control_in: this.state.control_in.value,
      control_status: this.state.control_status,
      results: this.state.results,
      coordinate_system: this.state.coordinate_system.value,
      survey_done: this.state.survey_done,
      project_notes: this.state.project_notes.value,
      survey_notes: this.state.survey_notes.value,
      control_lidar_flight: this.state.control_lidar_flight,
      survey_mapping_complete: this.state.survey_mapping_complete,
    };
    $.post("/cascopt/surveys", { survey: survey })
      .done((data) => {
        this.props.handleNewSurvey(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false });
      });
  }

  deleteSurvey = () => {
    if (confirm("Are you sure you want to delete this survey?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/surveys/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push("/cascopt/surveys");
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("Survey deletion failed!");
        });
    }
  };

  resetFormErrors() {
    this.setState({ formErrors: {} });
  }

  handleChange(e) {
    this.handleUserInput(
      e.target.name,
      e.target.value,
      SurveyForm.formValidations[e.target.name]
    );
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ? "Update Survey " : "Create Survey "}
          for {this.state.job_name.value} |{" "}
          {this.state.client_number.value || ""}-
          {this.state.job_number.value || ""}
        </h4>
        <p>
          Due: {moment(this.state.due_date.value || "").format("MM/DD/YYYY")}
        </p>
        <p>Client: {this.state.client_name.value}</p>
        <p>
          Location: {this.state.project_location.value} | Est. Flight Date:{" "}
          {moment(this.state.est_flight_date.value || "").format("MM/DD/YYYY")}
        </p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col">
              <label>Survey Staff *</label>
              <input
                type="text"
                name="survey_staff"
                placeholder="Survey Staff"
                required
                value={this.state.survey_staff.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Results *</label>
              <Select
                required
                name="results"
                value={this.state.results}
                onChange={this.handleSelectChange("results")}
                options={[
                  { value: "Good", label: "Good" },
                  { value: "Bad", label: "Bad" },
                  { value: "Fair", label: "Fair" },
                  { value: "None", label: "None" },
                ]}
              />
            </div>
            <div className="col">
              <label>Coordinate System *</label>
              <input
                type="text"
                name="coordinate_system"
                placeholder="Coordinate System"
                required
                value={this.state.coordinate_system.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>ABGPS</label>
              <Select
                required
                name="abgps"
                disabled={true}
                value={this.state.abgps}
                onChange={this.handleSelectChange("abgps")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Control Status *</label>
              <Select
                required
                name="control_status"
                value={this.state.control_status}
                onChange={this.handleSelectChange("control_status")}
                options={[
                  { value: "Not Received", label: "Not Received" },
                  { value: "Received", label: "Received" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Control Sent to LiDAR Flight Sub</label>
              <Select
                required
                name="control_lidar_flight"
                value={this.state.control_lidar_flight}
                onChange={this.handleSelectChange("control_lidar_flight")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Control In *</label>
              <input
                name="control_in"
                type="date"
                value={this.state.control_in.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Survey Done *</label>
              <Select
                required
                name="survey_done"
                value={this.state.survey_done}
                onChange={this.handleSelectChange("survey_done")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Sent GCP *</label>
              <input
                name="sent_gcp"
                type="date"
                value={this.state.sent_gcp.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Needs Shifted *</label>
              <Select
                required
                name="needs_shifted"
                value={this.state.needs_shifted}
                onChange={this.handleSelectChange("needs_shifted")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                  { value: "Yes, Shift Complete", label: "Yes, Shift Complete" },
                ]}
              />
            </div>
          </div>
          {this.state.needs_shifted === "Yes" && (
            <div className="row">
              <p />
              <div className="col">
                <label>
                  Known System (Add Coord System and Math to get from known
                  system to local system) *
                </label>
                <input
                  name="known_system"
                  type="text"
                  value={this.state.known_system.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
          )}
          <p />
          {this.state.project_type == "Survey Mapping" && (
            <div className="row">
              <div className="col-md-3">
                <label>Survey Mapping Complete</label>
                <Select
                  name="survey_mapping_complete"
                  value={this.state.survey_mapping_complete}
                  onChange={this.handleSelectChange("survey_mapping_complete")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
          )}
          <p />
          <div className="row">
            <div className="col">
              <label>Survey Notes *</label>
              <textarea
                name="survey_notes"
                placeholder="Survey Notes"
                rows="4"
                cols="40"
                value={this.state.survey_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea
                name="project_notes"
                placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
