import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import ProposalForm from "./ProposalForm";

export default class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: props.quote,
      opportunity: props.opportunity,
      proposals: [],
      specification: props.specification,
      isHidden: true,
      additionalPlots: props.plots
        ? Object.keys(props.plots).map((key) => props.plots[key])
        : [],
      check_plot_qty: "0",
      check_plot_hp: "0",
      check_plot_cp: "0",
      georef_qty: "0",
      georef_hp: "0",
      georef_cp: "0",
      georect_qty: "0",
      georect_hp: "0",
      georect_cp: "0",
      dig_plots_qty: "0",
      dig_plots_hp: "0",
      dig_plots_cp: "0",
      photo_enlarge_qty: "0",
      photo_enlarge_hp: "0",
      photo_enlarge_cp: "0",
      gis_qty: "0",
      gis_hp: "0",
      gis_cp: "0",
      lis_qty: "0",
      lis_hp: "0",
      lis_cp: "0",
      plot_x: "0",
      plot_y: "0",
      plot_shipping: "0",
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  static propTypes = {
    quote: PropTypes.object,
  };

  static defaultProps = {
    quote: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/quotes/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  addNewProposal = (proposal) => {
    const proposals = update(this.state.proposals, { $push: [proposal] });
    this.setState({
      proposals: proposals.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      }),
    });
  };

  calculateCost(plot) {
    if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 225 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 175 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 175 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 125 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "None" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "None" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "None" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] <= "42" &&
      plot["size_y"] <= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "None" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 50 * plot["quantity"];
      return additionalPlotTotal;
      {
        /* plots where longest side is 42 - 56 and shorter side is < 42 */
      }
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 275 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 225 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 325 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 225 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 250 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "None" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "None" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "None" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 125 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "42" &&
        plot["size_x"] <= "56" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "42" &&
        plot["size_y"] <= "56" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "None" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 75 * plot["quantity"];
      return additionalPlotTotal;
      {
        /* plots where longest side is 56 - 80 and shorter side is > 42 */
      }
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 475 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 375 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "3/16" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 375 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "1/2" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 275 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "None" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "Yes" &&
        plot["mounted"] == "None" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "None" &&
        plot["frame"] != "None")
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      (plot["size_x"] >= "56" &&
        plot["size_x"] <= "80" &&
        plot["size_y"] <= "42") ||
      (plot["size_x"] <= "42" &&
        plot["size_y"] >= "56" &&
        plot["size_y"] <= "80" &&
        plot["laminated"] == "No" &&
        plot["mounted"] == "None" &&
        plot["frame"] == "None")
    ) {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
      {
        /* plots where longest side is 56 - 80 and shorter side is 42 - 90 */
      }
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 800 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 800 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 950 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 750 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 750 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 550 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "None" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "None" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "None" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "56" &&
      plot["size_x"] <= "80" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "None" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
      {
        /* plots where longest side is 80 - 120 and shorter side is 42 - 90 */
      }
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 1200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 900 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 1400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 1100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 900 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "3/16" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 1100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "1/2" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 800 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "None" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 900 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "Yes" &&
      plot["mounted"] == "None" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "None" &&
      plot["frame"] != "None"
    ) {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (
      plot["size_x"] >= "80" &&
      plot["size_x"] <= "120" &&
      plot["size_y"] >= "42" &&
      plot["laminated"] == "No" &&
      plot["mounted"] == "None" &&
      plot["frame"] == "None"
    ) {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else {
      let additionalPlotTotal = 0;
      return additionalPlotTotal;
    }
  }

  render() {
    const { additionalPlots } = this.state;

    if (this.state.quote.quote_type == "Digital (Mapping Only)" || this.state.quote.quote_type == "Digital (Mapping Only) - Photo Geodetic") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.quote.survey_cost = surveyQuoteCost;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseInt(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      var mapSubtotal =
        parseInt(analyticsTotal) +
        parseInt(photogrammetryTotal) +
        parseInt(xsecTotal) +
        parseInt(cartographyTotal) +
        parseInt(translationTotal);
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt("0");
      var rectificationCp = parseInt("0");
      var rectificationHp = parseFloat("0");
      var rectificationHours = parseInt("0") * parseFloat("0");
      var rectificationPrice = parseInt("0") * parseInt("0");
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt("0");
      var plotCp = parseInt("0");
      var plotPrice = parseInt("0") * parseInt("0");
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt("0");
      var deliveryCp = parseInt("0");
      var deliveryPrice = parseInt("0") * parseInt("0");
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.quote.lidar_flight_cost = lidarFlightQuoteCost;

      {
        /* lidar level of detail pricing */
      }
      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.detail_level);
      var processHours = parseInt(this.state.lidar_size);
      if (this.state.size_type == "Acres" && this.state.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(1000) && this.state.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(10000) && this.state.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var processPrice = processTotal;
    } else if (this.state.quote.quote_type == "Digital (Image Only)" || this.state.quote.quote_type == "Digital (Image Only) - Photo Geodetic") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.quote.survey_cost = surveyQuoteCost;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt("0");
      var photogrammetryCp = parseInt("0");
      var photogrammetryHp = parseFloat("0");
      var photogrammetryHours = parseInt("0") * parseFloat("0");
      var photogrammetryPrice = parseInt("0") * parseInt("0");
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt("0");
      var xsecCp = parseInt("0");
      var xsecHp = parseFloat("0");
      var xsecHours = parseInt("0") * parseFloat("0");
      var xsecPrice = parseInt("0") * parseInt("0");
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt("0");
      var cartographyCp = parseInt("0");
      var cartographyHp = parseFloat("0");
      var cartographyHours = parseInt("0") * parseFloat("0");
      var cartographyPrice = parseInt("0") * parseInt("0");
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt("0");
      var translationCp = parseInt("0");
      var translationHp = parseFloat("0");
      var translationHours = parseInt("0") * parseFloat("0");
      var translationPrice = parseInt("0") * parseInt("0");
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      var mapSubtotal =
        parseInt(analyticsTotal) +
        parseInt(photogrammetryTotal) +
        parseInt(xsecTotal) +
        parseInt(cartographyTotal) +
        parseInt(translationTotal);
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.quote.lidar_flight_cost = lidarFlightQuoteCost;

      {
        /* lidar level of detail pricing */
      }
      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.detail_level);
      var processHours = parseInt(this.state.lidar_size);
      if (this.state.size_type == "Acres" && this.state.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(1000) && this.state.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(10000) && this.state.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var processPrice = processTotal;
    } else if (this.state.quote.quote_type == "Film (Image Only)" || this.state.quote.quote_type == "Film (Image Only) - Photo Geodetic") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.survey_cost = surveyQuoteCost;

      {
        /* contact prints cost */
      }
      var contactPrintsQty = parseInt(this.state.specification.exposures);
      var contactPrintsCp = parseInt(this.state.quote.contact_prints_cp);
      var contactPrintsPrice =
        parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
      var contactPrintsTotal = contactPrintsPrice;

      {
        /* diapositives cost */
      }
      var diapositivesQty = parseInt(this.state.specification.exposures);
      var diapositivesCp = parseInt(this.state.quote.diapositives_cp);
      var diapositivesPrice =
        parseInt(diapositivesQty) * parseInt(diapositivesCp);
      var diapositivesTotal = diapositivesPrice;

      {
        /* scan cost */
      }
      var scanNumber = parseInt(this.state.specification.exposures);
      var scanCp = parseInt(this.state.quote.scan_cp);
      var scanPrice = parseInt(scanNumber) * parseInt(scanCp);
      var scanTotal = scanPrice;

      {
        /* photo subtotal cost */
      }
      if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(contactPrintsTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal =
          parseInt(contactPrintsTotal) +
          parseInt(diapositivesTotal) +
          parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal = parseInt(diapositivesTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(scanTotal);
      }
      {
        /* lab discount */
      }
      var discount =
        parseInt(photoSubtotal) * parseFloat(this.state.quote.lab_discount);
      var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
      {
        /* end of lab discount */
      }
      this.state.quote.lab_cost = photoPrice;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt("0");
      var photogrammetryCp = parseInt("0");
      var photogrammetryHp = parseFloat("0");
      var photogrammetryHours = parseInt("0") * parseFloat("0");
      var photogrammetryPrice = parseInt("0") * parseInt("0");
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt("0");
      var xsecCp = parseInt("0");
      var xsecHp = parseFloat("0");
      var xsecHours = parseInt("0") * parseFloat("0");
      var xsecPrice = parseInt("0") * parseInt("0");
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt("0");
      var cartographyCp = parseInt("0");
      var cartographyHp = parseFloat("0");
      var cartographyHours = parseInt("0") * parseFloat("0");
      var cartographyPrice = parseInt("0") * parseInt("0");
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt("0");
      var translationCp = parseInt("0");
      var translationHp = parseFloat("0");
      var translationHours = parseInt("0") * parseFloat("0");
      var translationPrice = parseInt("0") * parseFloat("0");
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      if (this.state.quote.order_xsec == "Yes") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(xsecTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      } else if (this.state.quote.order_xsec == "No") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      }
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }
      this.state.quote.img_cost = imgPrice;

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.lidar_flight_cost = lidarFlightQuoteCost;

      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.detail_level);
      var processHours = parseInt(this.state.lidar_size);
      if (this.state.size_type == "Acres" && this.state.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(1000) && this.state.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(10000) && this.state.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var processPrice = processTotal;
    } else if (this.state.quote.quote_type == "Film (Mapping Only)" || this.state.quote.quote_type == "Film (Mapping Only) - Photo Geodetic") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.survey_cost = surveyQuoteCost;

      {
        /* contact prints cost */
      }
      var contactPrintsQty = parseInt(this.state.specification.exposures);
      var contactPrintsCp = parseInt(this.state.quote.contact_prints_cp);
      var contactPrintsPrice =
        parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
      var contactPrintsTotal = contactPrintsPrice;

      {
        /* diapositives cost */
      }
      var diapositivesQty = parseInt(this.state.specification.exposures);
      var diapositivesCp = parseInt(this.state.quote.diapositives_cp);
      var diapositivesPrice =
        parseInt(diapositivesQty) * parseInt(diapositivesCp);
      var diapositivesTotal = diapositivesPrice;

      {
        /* scan cost */
      }
      var scanNumber = parseInt(this.state.specification.exposures);
      var scanCp = parseInt(this.state.quote.scan_cp);
      var scanPrice = parseInt(scanNumber) * parseInt(scanCp);
      var scanTotal = scanPrice;

      {
        /* photo subtotal cost */
      }
      if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(contactPrintsTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal =
          parseInt(contactPrintsTotal) +
          parseInt(diapositivesTotal) +
          parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal = parseInt(diapositivesTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(scanTotal);
      }
      {
        /* lab discount */
      }
      var discount =
        parseInt(photoSubtotal) * parseFloat(this.state.quote.lab_discount);
      var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
      {
        /* end of lab discount */
      }
      this.state.quote.lab_cost = photoPrice;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseFloat(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      if (this.state.quote.order_xsec == "Yes") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(xsecTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      } else if (this.state.quote.order_xsec == "No") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      }
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt("0");
      var rectificationCp = parseInt("0");
      var rectificationHp = parseFloat("0");
      var rectificationHours = parseInt("0") * parseFloat("0");
      var rectificationPrice = parseInt("0") * parseInt("0");
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt("0");
      var plotCp = parseInt("0");
      var plotPrice = parseInt("0") * parseInt("0");
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt("0");
      var deliveryCp = parseInt("0");
      var deliveryPrice = parseInt("0") * parseInt("0");
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }
      this.state.quote.img_cost = imgPrice;

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.lidar_flight_cost = lidarFlightQuoteCost;

      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.detail_level);
      var processHours = parseInt(this.state.lidar_size);
      if (this.state.size_type == "Acres" && this.state.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(1000) && this.state.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(10000) && this.state.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var processPrice = processTotal;
    } else if (this.state.quote.quote_type == "Film") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.survey_cost = surveyQuoteCost;

      {
        /* contact prints cost */
      }
      var contactPrintsQty = parseInt(this.state.specification.exposures);
      var contactPrintsCp = parseInt(this.state.quote.contact_prints_cp);
      var contactPrintsPrice =
        parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
      var contactPrintsTotal = contactPrintsPrice;

      {
        /* diapositives cost */
      }
      var diapositivesQty = parseInt(this.state.specification.exposures);
      var diapositivesCp = parseInt(this.state.quote.diapositives_cp);
      var diapositivesPrice =
        parseInt(diapositivesQty) * parseInt(diapositivesCp);
      var diapositivesTotal = diapositivesPrice;

      {
        /* scan cost */
      }
      var scanNumber = parseInt(this.state.specification.exposures);
      var scanCp = parseInt(this.state.quote.scan_cp);
      var scanPrice = parseInt(scanNumber) * parseInt(scanCp);
      var scanTotal = scanPrice;

      {
        /* photo subtotal cost */
      }
      if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(contactPrintsTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal =
          parseInt(contactPrintsTotal) +
          parseInt(diapositivesTotal) +
          parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal = parseInt(diapositivesTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(scanTotal);
      }
      {
        /* lab discount */
      }
      var discount =
        parseInt(photoSubtotal) * parseFloat(this.state.quote.lab_discount);
      var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
      {
        /* end of lab discount */
      }
      this.state.quote.lab_cost = photoPrice;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseFloat(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      if (this.state.quote.order_xsec == "Yes") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(xsecTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      } else if (this.state.quote.order_xsec == "No") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      }
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }
      this.state.quote.img_cost = imgPrice;

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.lidar_flight_cost = lidarFlightQuoteCost;

      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.quote.detail_level);
      var processHours = parseInt(this.state.quote.lidar_size);
      if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(1000) && this.state.quote.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(10000) && this.state.quote.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.quote.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.quote.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var lidarProcessPrice = processTotal;
    } else if (this.state.quote.quote_type == "Film - Photo Geodetic") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.survey_cost = surveyQuoteCost;

      {
        /* contact prints cost */
      }
      var contactPrintsQty = parseInt(this.state.quote.contact_prints);
      var contactPrintsCp = parseInt(this.state.quote.contact_prints_cp);
      var contactPrintsPrice =
        parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
      var contactPrintsTotal = contactPrintsPrice;

      {
        /* diapositives cost */
      }
      var diapositivesQty = parseInt(this.state.specification.exposures);
      var diapositivesCp = parseInt(this.state.quote.diapositives_cp);
      var diapositivesPrice =
        parseInt(diapositivesQty) * parseInt(diapositivesCp);
      var diapositivesTotal = diapositivesPrice;

      {
        /* scan cost */
      }
      var scanNumber = parseInt(this.state.specification.exposures);
      var scanCp = parseInt(this.state.quote.scan_cp);
      var scanPrice = parseInt(scanNumber) * parseInt(scanCp);
      var scanTotal = scanPrice;

      {
        /* photo subtotal cost */
      }
      if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(contactPrintsTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal =
          parseInt(contactPrintsTotal) +
          parseInt(diapositivesTotal) +
          parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal = parseInt(diapositivesTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(scanTotal);
      }
      {
        /* lab discount */
      }
      var discount =
        parseInt(photoSubtotal) * parseFloat(this.state.quote.lab_discount);
      var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
      {
        /* end of lab discount */
      }
      this.state.quote.lab_cost = photoPrice;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseFloat(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      if (this.state.quote.order_xsec == "Yes") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(xsecTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      } else if (this.state.quote.order_xsec == "No") {
        var mapSubtotal =
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      }
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }
      this.state.quote.img_cost = imgPrice;

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.lidar_flight_cost = lidarFlightQuoteCost;

      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.quote.detail_level);
      var processHours = parseInt(this.state.quote.lidar_size);
      if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(1000) && this.state.quote.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(10000) && this.state.quote.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.quote.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.quote.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var lidarProcessPrice = processTotal;
    }
    else if (this.state.quote.quote_type == "Digital") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.quote.survey_cost = surveyQuoteCost;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseInt(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      var mapSubtotal =
        parseInt(analyticsTotal) +
        parseInt(photogrammetryTotal) +
        parseInt(xsecTotal) +
        parseInt(cartographyTotal) +
        parseInt(translationTotal);
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.quote.lidar_flight_cost = lidarFlightQuoteCost;

      {
        /* lidar level of detail pricing */
      }
      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.quote.detail_level);
      var processHours = parseInt(this.state.quote.lidar_size);
      if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(1000) && this.state.quote.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(10000) && this.state.quote.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.quote.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var lidarProcessPrice = processTotal;
    } else if (this.state.quote.quote_type == "Digital - Photo Geodetic") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.quote.survey_cost = surveyQuoteCost;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseInt(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      var mapSubtotal =
        parseInt(analyticsTotal) +
        parseInt(photogrammetryTotal) +
        parseInt(xsecTotal) +
        parseInt(cartographyTotal) +
        parseInt(translationTotal);
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.quote.lidar_flight_cost = lidarFlightQuoteCost;

      {
        /* lidar level of detail pricing */
      }
      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.quote.detail_level);
      var processHours = parseInt(this.state.quote.lidar_size);
      if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(1000) && this.state.quote.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(10000) && this.state.quote.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.quote.size_type == "Acres" && this.state.quote.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.quote.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var lidarProcessPrice = processTotal;
    }
    else if (this.state.quote.quote_type == "ADOT") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.quote.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.survey_cost = surveyQuoteCost;

      {
        /* contact prints cost */
      }
      var contactPrintsQty = parseInt(this.state.specification.exposures);
      var contactPrintsCp = parseInt(this.state.quote.contact_prints_cp);
      var contactPrintsPrice =
        parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
      var contactPrintsTotal = contactPrintsPrice;

      {
        /* diapositives cost */
      }
      var diapositivesQty = parseInt(this.state.specification.exposures);
      var diapositivesCp = parseInt(this.state.quote.diapositives_cp);
      var diapositivesPrice =
        parseInt(diapositivesQty) * parseInt(diapositivesCp);
      var diapositivesTotal = diapositivesPrice;

      {
        /* scan cost */
      }
      var scanNumber = parseInt(this.state.specification.exposures);
      var scanCp = parseInt(this.state.quote.scan_cp);
      var scanPrice = parseInt(scanNumber) * parseInt(scanCp);
      var scanTotal = scanPrice;

      {
        /* photo subtotal cost */
      }
      if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(contactPrintsTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "Yes" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal =
          parseInt(contactPrintsTotal) +
          parseInt(diapositivesTotal) +
          parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "Yes"
      ) {
        var photoSubtotal = parseInt(diapositivesTotal) + parseInt(scanTotal);
      } else if (
        this.state.quote.order_cp == "No" &&
        this.state.quote.order_diap == "No"
      ) {
        var photoSubtotal = parseInt(scanTotal);
      }
      {
        /* lab discount */
      }
      var discount =
        parseInt(photoSubtotal) * parseFloat(this.state.quote.lab_discount);
      var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
      {
        /* end of lab discount */
      }
      this.state.quote.lab_cost = photoPrice;

      {
        /* check plots cost */
      }
      var checkPlotsNumber = parseInt(this.state.quote.check_plot_qty);
      var checkPlotCp = parseFloat(this.state.quote.check_plot_cp);
      var checkPlotHp = parseInt(this.state.quote.check_plot_hp);
      var checkPlotHours = checkPlotsNumber * checkPlotHp;
      var checkPlotPrice = checkPlotCp * checkPlotHours;
      var checkPlotTotal = checkPlotPrice;

      {
        /* gis cost */
      }
      var gisQty = this.state.quote.gis_qty;
      var gisCp = this.state.quote.gis_cp;
      var gisHp = this.state.quote.gis_hp;
      var gisHours = gisQty * gisHp;
      var gisPrice = gisCp * gisHours;
      var gisTotal = gisPrice;

      {
        /* lis/his cost */
      }
      var lisQty = this.state.quote.lis_qty;
      var lisCp = this.state.quote.lis_cp;
      var lisHp = this.state.quote.lis_hp;
      var lisHours = lisQty * lisHp;
      var lisPrice = lisCp * lisHours;
      var lisTotal = lisPrice;

      {
        /* analytics cost */
      }
      var analyticsNumber = parseInt(this.state.specification.exposures);
      var analyticsCp = parseInt(this.state.quote.analytics_cp);
      var analyticsHp = parseFloat(this.state.quote.analytics_hp);
      var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
      var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
      var analyticsTotal = analyticsPrice;

      {
        /* photogrammetry cost */
      }
      var photogrammetryNumber = parseInt(this.state.specification.models);
      var photogrammetryCp = parseInt(this.state.quote.photogrammetry_cp);
      var photogrammetryHp = parseFloat(this.state.quote.photogrammetry_hp);
      var photogrammetryHours =
        parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
      var photogrammetryPrice =
        parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
      var photogrammetryTotal = photogrammetryPrice;

      {
        /* x-section cost */
      }
      var xsecQty = parseInt(this.state.specification.models);
      var xsecCp = parseInt(this.state.quote.xsec_cp);
      var xsecHp = parseFloat(this.state.quote.xsec_hp);
      var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
      var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
      var xsecTotal = xsecPrice;

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.specification.models);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.specification.models);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseInt(translationHours);
      var translationTotal = translationPrice;

      {
        /* mapping subtotal cost */
      }
      if (this.state.quote.order_xsec == "Yes") {
        var mapSubtotal =
          lisTotal +
          gisTotal +
          checkPlotTotal +
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(xsecTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      } else if (this.state.quote.order_xsec == "No") {
        var mapSubtotal =
          lisTotal +
          gisTotal +
          checkPlotTotal +
          parseInt(analyticsTotal) +
          parseInt(photogrammetryTotal) +
          parseInt(cartographyTotal) +
          parseInt(translationTotal);
      }
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.quote.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* georeferencing cost */
      }
      var georefQty = this.state.quote.georef_qty;
      var georefCp = this.state.quote.georef_cp;
      var georefHp = this.state.quote.georef_hp;
      var georefHours = georefQty * georefHp;
      var georefPrice = georefCp * georefHours;
      var georefTotal = georefPrice;

      {
        /* georectification cost */
      }
      var georectQty = this.state.quote.georect_qty;
      var georectCp = this.state.quote.georect_cp;
      var georectHp = this.state.quote.georect_hp;
      var georectHours = georectQty * georectHp;
      var georectPrice = georectCp * georectHours;
      var georectTotal = georectPrice;

      {
        /* digital plots cost */
      }
      var digPlotQty = this.state.quote.dig_plots_qty;
      var digPlotCp = this.state.quote.dig_plots_cp;
      var digPlotHp = this.state.quote.dig_plots_hp;
      var digPlotHours = digPlotQty * digPlotHp;
      var digPlotPrice = digPlotCp * digPlotHours;
      var digPlotTotal = digPlotPrice;

      {
        /* photo enlargement cost */
      }
      var photoEnlargeQty = this.state.quote.photo_enlarge_qty;
      var photoEnlargeCp = this.state.quote.photo_enlarge_cp;
      var photoEnlargeHp = this.state.quote.photo_enlarge_hp;
      var photoEnlargeHours = photoEnlargeQty * photoEnlargeHp;
      var photoEnlargePrice = photoEnlargeCp * photoEnlargeHours;
      var photoEnlargeTotal = photoEnlargePrice;

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        photoEnlargeTotal +
        digPlotTotal +
        georectTotal +
        georefTotal +
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.quote.lidar_flight_cost = lidarFlightQuoteCost;

      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.detail_level);
      var processHours = parseInt(this.state.lidar_size);
      if (this.state.size_type == "Acres") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }
      var processPrice = processTotal;

      var lidarSubtotal = lidarFlightQuoteCost + processTotal;
    } else if (this.state.quote.quote_type == "LiDAR") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* lidar flight cost */
      }
      var lidarFlightMarkup =
        parseInt(this.state.quote.lidar_flight_quote) *
        parseFloat(this.state.quote.lidar_flight_markup);
      var lidarFlightCost =
        lidarFlightMarkup / 100 + parseInt(this.state.quote.lidar_flight_quote);
      var lidarFlightQuoteCost = lidarFlightCost;
      this.state.quote.lidar_flight_cost = lidarFlightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.quote.survey_cost = surveyQuoteCost;

      {/* lidar level of detail pricing */ }
      var detailPrice = parseFloat(this.state.detail_level);
      var processHours = parseInt(this.state.lidar_size);
      if (this.state.size_type == "Acres" && this.state.lidar_size <= parseInt(1000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(1000) && this.state.lidar_size <= parseInt(10000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(10000) && this.state.lidar_size <= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
      } else if (this.state.size_type == "Acres" && this.state.lidar_size >= parseInt(20000)) {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
      } else if (this.state.size_type == "Linear Feet") {
        var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
      }

      var processPrice = processTotal;

      {
        /* processing section totals */
      }
      var processSubtotal =
        processPrice + parseInt(cartographyTotal) + parseInt(translationTotal);
      var total =
        processPrice + parseInt(cartographyTotal) + parseInt(translationTotal);

      {
        /* cartography cost */
      }
      var cartographyNumber = parseInt(this.state.quote.cartography_qty);
      var cartographyCp = parseInt(this.state.quote.cartography_cp);
      var cartographyHp = parseFloat(this.state.quote.cartography_hp);
      var cartographyHours =
        parseInt(cartographyNumber) * parseFloat(cartographyHp);
      var cartographyPrice =
        parseInt(cartographyCp) * parseInt(cartographyHours);
      var cartographyTotal = cartographyPrice;

      {
        /* translation cost */
      }
      var translationQty = parseInt(this.state.quote.translation);
      var translationCp = parseInt(this.state.quote.translation_cp);
      var translationHp = parseFloat(this.state.quote.translation_hp);
      var translationHours =
        parseInt(translationQty) * parseFloat(translationHp);
      var translationPrice =
        parseInt(translationCp) * parseInt(translationHours);
      var translationTotal = translationPrice;

      {
        /* rectification cost */
      }
      var rectificationQty = parseInt(this.state.specification.exposures);
      var rectificationCp = parseInt(this.state.quote.img_cp);
      var rectificationHp = parseFloat(this.state.quote.img_hp);
      var rectificationHours =
        parseInt(rectificationQty) * parseFloat(rectificationHp);
      var rectificationPrice =
        parseInt(rectificationCp) * parseInt(rectificationHours);
      var rectificationTotal = rectificationPrice;

      {
        /* mapping subtotal cost */
      }
      var mapSubtotal =
        parseInt(cartographyTotal) +
        parseInt(translationTotal) +
        parseInt(processPrice);
      {
        /* map discount */
      }
      var mapDiscount =
        parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
      var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
      {
        /* end of map discount */
      }

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal =
        parseInt(rectificationTotal) +
        parseInt(plotTotal) +
        parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }
    } else if (this.state.quote.quote_type == "Scan") {
      {
        /* contact prints cost */
      }
      var contactPrintsQty = parseInt(this.state.quote.contact_prints);
      var contactPrintsCp = parseInt(this.state.quote.contact_prints_cp);
      var contactPrintsPrice =
        parseInt(contactPrintsQty) * parseInt(contactPrintsCp);
      var contactPrintsTotal = contactPrintsPrice;

      {
        /* diapositives cost */
      }
      var diapositivesQty = parseInt(this.state.quote.diapositives);
      var diapositivesCp = parseInt(this.state.quote.diapositives_cp);
      var diapositivesPrice =
        parseInt(diapositivesQty) * parseInt(diapositivesCp);
      var diapositivesTotal = diapositivesPrice;

      {
        /* scan cost */
      }
      var scanNumber = parseFloat(this.state.quote.scan_qty);
      var scanCp = parseFloat(this.state.quote.scan_cp);
      var scanPrice = parseFloat(scanNumber) * parseFloat(scanCp);
      var scanTotal = scanPrice;

      {
        /* photo subtotal cost */
      }
      var photoSubtotal =
        parseInt(contactPrintsTotal) +
        parseInt(diapositivesTotal) +
        parseInt(scanTotal);
      {
        /* lab discount */
      }
      var discount =
        parseInt(photoSubtotal) * parseFloat(this.state.lab_discount);
      var photoPrice = parseInt(photoSubtotal) - parseInt(discount);
      {
        /* end of lab discount */
      }

      {
        /* plots cost */
      }
      var plotQty = parseInt(this.state.quote.plots);
      var plotCp = parseInt(this.state.quote.plot_cp);
      var plotPrice = parseInt(plotQty) * parseInt(plotCp);
      var plotTotal = plotPrice;

      {
        /* delivery cost */
      }
      var deliveryQty = parseInt(this.state.quote.delivery);
      var deliveryCp = parseInt(this.state.quote.delivery_cp);
      var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
      var deliveryTotal = deliveryPrice;

      {
        /* imagery cost */
      }
      var imageSubtotal = parseInt(plotTotal) + parseInt(deliveryTotal);
      {
        /* image discount */
      }
      var imgDiscount =
        parseInt(imageSubtotal) * parseFloat(this.state.quote.img_discount);
      var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
      {
        /* end of image discount */
      }
    } else if (this.state.quote.quote_type == "UAV") {
      {
        /* flight cost */
      }
      var flightMarkup =
        parseInt(this.state.quote.flight_quote) *
        parseFloat(this.state.quote.flight_markup);
      var flightCost =
        flightMarkup / 100 + parseInt(this.state.quote.flight_quote);
      var flightQuoteCost = flightCost;
      this.state.flight_cost = flightQuoteCost;

      {
        /* survey cost */
      }
      var surveyMarkup =
        parseInt(this.state.quote.survey_quote) *
        parseFloat(this.state.quote.survey_markup);
      var surveyCost =
        surveyMarkup / 100 + parseInt(this.state.quote.survey_quote);
      var surveyQuoteCost = surveyCost;
      this.state.survey_cost = surveyQuoteCost;

      {
        /* mapping section cost */
      }
      var exposures = parseInt(this.state.specification.exposures);
      var exposureCostPer = parseInt(this.state.quote.img_cp);
      var mappingTotal = exposures * exposureCostPer;

      {
        /* obliques section cost */
      }
      var obliquesExposures = parseInt(this.state.quote.xsec);
      var obliquesCostPer = parseInt(this.state.quote.scan_cp);
      var obliquesTotal = obliquesExposures * obliquesCostPer;

      {
        /* video section cost */
      }
      var videos = parseInt(this.state.specification.total_videos);
      var videoCostPer = parseInt(this.state.quote.contact_prints_cp);
      var videoTotal = videos * videoCostPer;
    } else if (this.state.quote.quote_type == "BIM") {
      var hours = parseInt(this.state.quote.analytics_qty);
      var hoursCostPer = parseInt(this.state.quote.analytics_cp);
      var hoursCost =
        parseInt(this.state.quote.analytics_qty) *
        parseInt(this.state.quote.analytics_cp);
      var hoursTotal = hoursCost;

      var qcHours = parseInt(this.state.quote.photogrammetry_qty);
      var qcHoursCostPet = parseInt(this.state.quote.photogrammetry_cp);
      var qcCost =
        parseInt(this.state.quote.photogrammetry_qty) *
        parseInt(this.state.quote.photogrammetry_cp);
      var qcTotal = qcCost;

      var bimCost = hoursTotal + qcTotal;
      var bimTotal = bimCost;
      this.state.quote.map_cost = bimTotal;
      this.state.quote.quote_cost = bimTotal;
    } else if (this.state.quote.quote_type == "Survey") {
      {
        /* survey services cost */
      }
      var rlsTotal =
        parseInt(this.state.quote.rls_cp) *
        parseFloat(this.state.quote.rls_hrs);
      var spcTotal =
        parseInt(this.state.quote.spc_cp) *
        parseFloat(this.state.quote.spc_hrs);
      var stTotal =
        parseInt(this.state.quote.st_cp) * parseFloat(this.state.quote.st_hrs);
      var sc2Total =
        parseInt(this.state.quote.sc2_cp) *
        parseFloat(this.state.quote.sc2_hrs);
      var sc3Total =
        parseInt(this.state.quote.sc3_cp) *
        parseFloat(this.state.quote.sc3_hrs);
      var rwTotal =
        parseInt(this.state.quote.rw_plans_cp) *
        parseFloat(this.state.quote.rw_plans_hrs);
      var servicesTotal =
        rlsTotal + spcTotal + stTotal + sc2Total + sc3Total + rwTotal;
    }

    return (
      <div className="card">
        <div className="card-body">
          {this.state.quote.quote_type == "Film (Image Only)" ||
            (this.state.quote.quote_type ==
              "Film (Image Only) - Photo Geodetic" && (
              <div>
                <div className="row">
                  <div className="col-md-4">
                    <label>Quote Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_type || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Flight</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Flight Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.flight_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Survey</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Is Client Doing Survey? *</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.client_survey || "No"}
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Panels</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.panels || ""}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>ABGPS</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.abgps || ""}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Surveyor</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Survey Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.survey_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Photo Lab</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Contact Prints Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per Contact Print</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.contact_prints_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Contact Print Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={contactPrintsTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Diapositives Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per Diapositive</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.diapositives_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Diapositive Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={diapositivesTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Scanning Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per Scan</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.scan_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Scanning Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={scanTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={photoSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lab_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Lab Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lab_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Photolab Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lab_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Mapping</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Analytics Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={analyticsHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.analytics_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Analytics Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={analyticsTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={mapSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Mapping Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Imagery</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={rectificationHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Rectification Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={rectificationTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Plots</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plots || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot X</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_x || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Y</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_y || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Mount</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_mount || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Laminate</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_laminate || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.plot_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Shipping</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_shipping || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={plotTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Delivery</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Delivery Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery_type || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.delivery_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={deliveryTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={imageSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Imagery Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div>
                  <h4 className="mb-4 text-4xl">LiDAR</h4>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Flight Sub</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_sub || "None"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Quote</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_quote || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Markup</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_markup || "0"}
                          className="form-control"
                        />
                        <span className="input-group-text">%</span>
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Cost</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_cost || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Size</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_size || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Size Type</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.size_type || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>L.O.D.</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.detail_level || "0"}
                        className="form-control"
                      />
                    </div>
                    {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                    <div className="col">
                      <label>Total</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={lidarProcessPrice || ""}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>PPSM</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_ppsm || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Bare Earth Only</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_bare_earth || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Notes</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_notes || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Total Adjustment</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={
                          this.state.quote.total_adjustment.toFixed(2) || "0"
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Explanation</label>
                    <textarea
                      readOnly
                      value={this.state.quote.adjustment_reason || "n/a"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Bonus Percentage</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.bonus_perc || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <label>Total Quote Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.quote_cost.toFixed(2) || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
              </div>
            ))}
          {this.state.quote.quote_type == "Film (Image Only)" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Photo Lab</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Contact Prints Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Contact Print</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Contact Print Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={contactPrintsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Diapositives Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Diapositive</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.diapositives_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Diapositive Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={diapositivesTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Scanning Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Scan</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Scanning Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={scanTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Lab Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photolab Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lab_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Rectification Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot X</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_x || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Y</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_y || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Shipping</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_shipping || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={lidarProcessPrice || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col-md-3">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Film (Mapping Only)" ||
            (this.state.quote.quote_type ==
              "Film (Mapping Only) - Photo Geodetic" && (
              <div>
                <div className="row">
                  <div className="col-md-4">
                    <label>Quote Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_type || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Flight</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Flight Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.flight_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Survey</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Is Client Doing Survey? *</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.client_survey || "No"}
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Panels</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.panels || ""}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>ABGPS</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.abgps || ""}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Surveyor</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Survey Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.survey_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Photo Lab</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Contact Prints Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per Contact Print</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.contact_prints_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Contact Print Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={contactPrintsTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Diapositives Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per Diapositive</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.diapositives_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Diapositive Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={diapositivesTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Scanning Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per Scan</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.scan_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Scanning Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={scanTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={photoSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lab_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Lab Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lab_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Photolab Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lab_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Mapping</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Analytics Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={analyticsHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.analytics_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Analytics Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={analyticsTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Photogrammetry Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.photogrammetry_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Photogrammetry Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={photogrammetryTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>X Section Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={xsecHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.xsec_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>X Section Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={xsecTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Cartography Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={cartographyHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.cartography_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Cartography Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={cartographyTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Translation Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={translationHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.translation_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Translation Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={translationTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={mapSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Mapping Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div>
                  <h4 className="mb-4 text-4xl">LiDAR</h4>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Flight Sub</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_sub || "None"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Quote</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_quote || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Markup</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_markup || "0"}
                          className="form-control"
                        />
                        <span className="input-group-text">%</span>
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Cost</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_cost || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Size</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_size || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Size Type</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.size_type || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>L.O.D.</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.detail_level || "0"}
                        className="form-control"
                      />
                    </div>
                    {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                    <div className="col">
                      <label>Total</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={processPrice || ""}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>PPSM</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_ppsm || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Bare Earth Only</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_bare_earth || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Notes</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_notes || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Total Adjustment</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={
                          this.state.quote.total_adjustment.toFixed(2) || "0"
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Explanation</label>
                    <textarea
                      readOnly
                      value={this.state.quote.adjustment_reason || "n/a"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Bonus Percentage</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.bonus_perc || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Total Quote Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.quote_cost.toFixed(2) || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
              </div>
            ))}
          {this.state.quote.quote_type == "Film (Mapping Only)" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Photo Lab</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Contact Prints Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Contact Print</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Contact Print Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={contactPrintsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Diapositives Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Diapositive</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.diapositives_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Diapositive Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={diapositivesTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Scanning Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Scan</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Scanning Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={scanTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Lab Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photolab Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lab_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={processPrice || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "BIM" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">BIM</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>BIM Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.analytics_cp || "0")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(hoursTotal || "0")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>QC Hrs for BIM</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.photogrammetry_cp || "0")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(qcTotal || "0")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>BIM Level of Detail</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.bim_lod || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <a href="/bim_lod" className="view-record" target="_blank">
                    What is BIM Level of Detail?
                  </a>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>BIM Cost</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.map_cost || "0")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      )}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.quote_cost.toFixed(2) || "")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Film" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Photo Lab</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Contact Prints Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Contact Print</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Contact Print Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={contactPrintsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Diapositives Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Diapositive</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.diapositives_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Diapositive Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={diapositivesTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Scanning Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Scan</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Scanning Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={scanTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Lab Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photolab Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lab_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                {this.state.specification.diff_img_flight == "No" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.img_exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot X</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_x || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Y</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_y || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Shipping</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_shipping || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={lidarProcessPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Film - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Photo Lab</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Contact Prints Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.contact_prints || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Contact Print</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Contact Print Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={contactPrintsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Diapositives Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Diapositive</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.diapositives_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Diapositive Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={diapositivesTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Scanning Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Scan</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Scanning Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={scanTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Lab Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photolab Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lab_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                {this.state.specification.diff_img_flight == "No" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.img_exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot X</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_x || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Y</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_y || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Shipping</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_shipping || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={lidarProcessPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "ADOT" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Photo Lab</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Contact Prints Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Contact Print</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Contact Print Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={contactPrintsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Diapositives Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Diapositive</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.diapositives_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Diapositive Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={diapositivesTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Scanning Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Scan</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Scanning Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={scanTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Lab Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photolab Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lab_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Check Plots *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.check_plot_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.check_plot_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs *</label>
                  <input
                    type="text"
                    readOnly
                    value={checkPlotHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.check_plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={checkPlotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>GIS *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.gis_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.gis_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs *</label>
                  <input
                    type="text"
                    readOnly
                    value={gisHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.gis_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={gisTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>LIS/HIS Quantity *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lis_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lis_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs *</label>
                  <input
                    type="text"
                    readOnly
                    value={lisHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lis_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={lisTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Georeferenced *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.georef_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.georef_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={georefHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.georef_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={georefTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Georectified *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.georect_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.georect_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={georectHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.georect_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={georectTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Digital Plots *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.dig_plots_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.dig_plots_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={digPlotHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.dig_plots_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={digPlotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Photo Enlargement *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photo_enlarge_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs Per</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photo_enlarge_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photoEnlargeHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photo_enlarge_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoEnlargeTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Rectification Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={processPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Other" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Digital" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                {this.state.specification.diff_img_flight == "No" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.img_exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={lidarProcessPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Digital - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                {this.state.specification.diff_img_flight == "No" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.img_exposures || "0"}
                      className="form-control"
                    />
                  </div>
                )}
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={lidarProcessPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Digital (Mapping Only)" ||
            (this.state.quote.quote_type ==
              "Digital (Mapping Only) - Photo Geodetic" && (
              <div>
                <div className="row">
                  <div className="col-md-4">
                    <label>Quote Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_type || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Flight</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Flight Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.flight_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Survey</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Is Client Doing Survey? *</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.client_survey || "No"}
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Panels</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.panels || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>ABGPS</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.abgps || ""}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Surveyor</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Survey Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.survey_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Mapping</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Analytics Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={analyticsHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.analytics_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Analytics Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={analyticsTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Photogrammetry Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.photogrammetry_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Photogrammetry Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={photogrammetryTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>X Section Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={xsecHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.xsec_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>X Section Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={xsecTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Cartography Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={cartographyHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.cartography_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Cartography Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={cartographyTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Translation Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={translationHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.translation_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Translation Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={translationTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={mapSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Mapping Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Imagery</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={rectificationHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Rectification Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={rectificationTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Plots</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plots || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Size</label>
                    <input type="text" readOnly className="form-control" />
                  </div>
                  <div className="col">
                    <label>Plot Mount</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_mount || "n/a"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Laminate</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_laminate || "n/a"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.plot_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Plot Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={plotTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Delivery</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Delivery Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery_type || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.delivery_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={deliveryTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={imageSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Imagery Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div>
                  <h4 className="mb-4 text-4xl">LiDAR</h4>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Flight Sub</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_sub || "None"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Quote</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_quote || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Markup</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_markup || "0"}
                          className="form-control"
                        />
                        <span className="input-group-text">%</span>
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Cost</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_cost || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Size</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_size || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Size Type</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.size_type || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>L.O.D.</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.detail_level || "0"}
                        className="form-control"
                      />
                    </div>
                    {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                    <div className="col">
                      <label>Total</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={processPrice || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>PPSM</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_ppsm || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Bare Earth Only</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_bare_earth || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Notes</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_notes || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Total Adjustment</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={
                          this.state.quote.total_adjustment.toFixed(2) || "0"
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Explanation</label>
                    <textarea
                      readOnly
                      value={this.state.quote.adjustment_reason || "n/a"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Bonus Percentage</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.bonus_perc || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Total Quote Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.quote_cost.toFixed(2) || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
              </div>
            ))}
          {this.state.quote.quote_type == "Digital (Mapping Only)" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Rectification Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={processPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Digital (Image Only)" ||
            (this.state.quote.quote_type ==
              "Digital (Image Only) - Photo Geodetic" && (
              <div>
                <div className="row">
                  <div className="col-md-4">
                    <label>Quote Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_type || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Flight</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Flight Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.flight_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Survey</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Is Client Doing Survey? *</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.client_survey || "No"}
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Panels</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.panels || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>ABGPS</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.abgps || ""}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Surveyor</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Survey Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Survey Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.survey_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Survey Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.survey_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Mapping</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Analytics Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={analyticsHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Analytics CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.analytics_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Analytics Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={analyticsTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Photogrammetry Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photogrammetry CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.photogrammetry_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Photogrammetry Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={photogrammetryTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>X Section Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={xsecHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>X Section CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.xsec_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>X Section Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={xsecTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Cartography Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={cartographyHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cartography CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.cartography_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Cartography Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={cartographyTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Translation Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.models || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={translationHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Translation CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.translation_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Translation Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={translationTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={mapSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Mapping Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.map_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Imagery</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Rectification Quantity</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.exposures || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification HP</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_hp || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification Total Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={rectificationHours || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Rectification CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Rectification Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={rectificationTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Plots</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plots || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Size</label>
                    <input type="text" readOnly className="form-control" />
                  </div>
                  <div className="col">
                    <label>Plot Mount</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_mount || "n/a"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot Laminate</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_laminate || "n/a"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Plot CP</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.plot_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Plot Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={plotTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Delivery</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Delivery Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery_type || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Cost Per</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.delivery_cp || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={deliveryTotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Subtotal</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={imageSubtotal || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Discount</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_discount || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>Imagery Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.img_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div>
                  <h4 className="mb-4 text-4xl">LiDAR</h4>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Flight Sub</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_sub || "None"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Quote</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_quote || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Markup</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_markup || "0"}
                          className="form-control"
                        />
                        <span className="input-group-text">%</span>
                      </div>
                    </div>
                    <div className="col">
                      <label>LiDAR Flight Cost</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.lidar_flight_cost || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Size</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_size || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Size Type</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.size_type || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>L.O.D.</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.detail_level || "0"}
                        className="form-control"
                      />
                    </div>
                    {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                    <div className="col">
                      <label>Total</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={processPrice || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>PPSM</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_ppsm || "0"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Bare Earth Only</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_bare_earth || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>LiDAR Notes</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_notes || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Total Adjustment</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={
                          this.state.quote.total_adjustment.toFixed(2) || "0"
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>Explanation</label>
                    <textarea
                      readOnly
                      value={this.state.quote.adjustment_reason || "n/a"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Bonus Percentage</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.bonus_perc || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Total Quote Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.quote_cost.toFixed(2) || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
              </div>
            ))}
          {this.state.quote.quote_type == "Digital (Image Only)" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Analytics Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.analytics_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={analyticsHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Analytics CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.analytics_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Analytics Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={analyticsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photogrammetry Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.photogrammetry_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={photogrammetryHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Photogrammetry CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.photogrammetry_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Photogrammetry Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photogrammetryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>X Section Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.xsec_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={xsecHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>X Section CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.xsec_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>X Section Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={xsecTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mapSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Mapping Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Rectification Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={processPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Plot" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              {this.state.quote.flight_cost > 0 && (
                <div>
                  <h4 className="mb-4 text-4xl">Flight</h4>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>Flight Sub</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.flight_sub || "None"}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Flight Quote</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.flight_quote || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <label>Flight Markup</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.flight_markup || "0"}
                          className="form-control"
                        />
                        <span className="input-group-text">%</span>
                      </div>
                    </div>
                    <div className="col">
                      <label>Flight Cost</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                          type="text"
                          readOnly
                          value={this.state.quote.flight_cost || "0"}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Flight Notes</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.quote.flight_notes || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              <h4 className="mb-4 text-4xl">Plotting</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Plot Description</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.description}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plot Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Length</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_x || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Width</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_y || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Frame</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.frame}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Laminated</label>
                  <input
                    type="text"
                    readOnly
                    value={this.props.specification.laminate}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.plot_options}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <hr />
              {additionalPlots.map((plot, idx) => (
                <div key={`plot-${idx}`}>
                  {this.state.additionalPlots[idx]["quantity"] >= 1 && (
                    <div>
                      <div className="row">
                        <div className="col">
                          <label>Quantity</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-quantity`}
                            value={this.state.additionalPlots[idx]["quantity"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Length *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-size_x`}
                            placeholder="Size X"
                            value={this.state.additionalPlots[idx]["size_x"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Width *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-size_y`}
                            placeholder="Size Y"
                            value={this.state.additionalPlots[idx]["size_y"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Mounted *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-mounted`}
                            value={this.state.additionalPlots[idx]["mounted"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Frame *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-frame`}
                            value={this.state.additionalPlots[idx]["frame"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Laminated</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-laminated`}
                            value={this.state.additionalPlots[idx]["laminated"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Type *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-plot_options`}
                            value={
                              this.state.additionalPlots[idx]["plot_options"]
                            }
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <label>Total *</label>
                          <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input
                              type="text"
                              readOnly
                              value={this.calculateCost(
                                this.state.additionalPlots[idx]
                              )}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}
                </div>
              ))}
              <p />
              <h4 className="mb-4 text-4xl">Delivery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.total_adjustment || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "LiDAR" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Processing</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Size</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_size || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.size_type || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>L.O.D.</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.detail_level || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={processPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>PPSM</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_ppsm || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Bare Earth Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_bare_earth || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={processPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Processing Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={processPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery Flight & Imagery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>LiDAR Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>LiDAR Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>LiDAR Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>LiDAR Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>LiDAR Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Rectification Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.img_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={rectificationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Rectification CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Rectification Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={rectificationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Plots</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Laminate</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_laminate || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "LiDAR Processing" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Processing</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Size</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_size || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.size_type || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>L.O.D.</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.detail_level || "0"}
                    className="form-control"
                  />
                </div>
                {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={processPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cartography Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.cartography_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={cartographyHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cartography CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.cartography_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Cartography Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={cartographyTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Translation Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation HP</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.translation_hp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation Total Hrs</label>
                  <input
                    type="text"
                    readOnly
                    value={translationHours || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Translation CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.translation_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Translation Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={translationTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={processPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.map_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Processing Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={processPrice || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Scan" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Photo Lab</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Contact Prints Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.contact_prints || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Contact Print</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Contact Print Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={contactPrintsTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Diapositives Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.diapositives || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Diapositive</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.diapositives_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Diapositive Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={diapositivesTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Scanning Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.scan_qty || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Scan</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Scanning Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={scanTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={photoSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Lab Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lab_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Photolab Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.lab_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Imagery</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Plot Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plots || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot Size</label>
                  <input type="text" readOnly className="form-control" />
                </div>
                <div className="col">
                  <label>Plot Mount</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.plot_mount || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Plot CP</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.plot_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Plot Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={plotTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Delivery</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Delivery Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.delivery_type || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.delivery_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={deliveryTotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Subtotal</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={imageSubtotal || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Discount</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_discount || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Imagery Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.img_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Survey" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost Per Panel</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Panel Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h5>Survey Services</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Registered Land Surveyor *</label>
                </div>
                <div className="col">
                  <label>RLS Hrs *</label>
                  <input
                    type="text"
                    name="rls_hrs"
                    readOnly
                    value={this.state.quote.rls_hrs || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>RLS CP *</label>
                  <input
                    type="text"
                    name="rls_cp"
                    readOnly
                    value={this.state.quote.rls_cp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>RLS Total *</label>
                  <input
                    type="text"
                    name="rlsTotal"
                    readOnly
                    value={rlsTotal || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Party Chief *</label>
                </div>
                <div className="col">
                  <label>SPC Hrs *</label>
                  <input
                    type="text"
                    name="spc_hrs"
                    readOnly
                    value={this.state.quote.spc_hrs || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>SPC CP *</label>
                  <input
                    type="text"
                    name="spc_cp"
                    readOnly
                    value={this.state.quote.spc_cp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>SPC Total *</label>
                  <input
                    type="text"
                    name="spcTotal"
                    readOnly
                    value={spcTotal || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Technician *</label>
                </div>
                <div className="col">
                  <label>ST Hrs *</label>
                  <input
                    type="text"
                    name="st_hrs"
                    readOnly
                    value={this.state.quote.st_hrs || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ST CP *</label>
                  <input
                    type="text"
                    name="st_cp"
                    readOnly
                    value={this.state.quote.st_cp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ST Total *</label>
                  <input
                    type="text"
                    name="stTotal"
                    readOnly
                    value={stTotal || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>2 Person Survey Crew *</label>
                </div>
                <div className="col">
                  <label>Hrs *</label>
                  <input
                    type="text"
                    name="sc2_hrs"
                    readOnly
                    value={this.state.quote.sc2_hrs || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>CP *</label>
                  <input
                    type="text"
                    name="sc2_cp"
                    readOnly
                    value={this.state.quote.sc2_cp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Total *</label>
                  <input
                    type="text"
                    name="sc2Total"
                    readOnly
                    value={sc2Total || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>3 Person Survey Crew *</label>
                </div>
                <div className="col">
                  <label>Hrs *</label>
                  <input
                    type="text"
                    name="sc3_hrs"
                    readOnly
                    value={this.state.quote.sc3_hrs || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>CP *</label>
                  <input
                    type="text"
                    name="sc3_cp"
                    readOnly
                    value={this.state.quote.sc3_cp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Total *</label>
                  <input
                    type="text"
                    name="sc3Total"
                    readOnly
                    value={sc3Total || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>R/W Plans Tech *</label>
                </div>
                <div className="col">
                  <label>Hrs *</label>
                  <input
                    type="text"
                    name="rw_plans_hrs"
                    readOnly
                    value={this.state.quote.rw_plans_hrs || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>CP *</label>
                  <input
                    type="text"
                    name="rw_plans_cp"
                    readOnly
                    value={this.state.quote.rw_plans_cp || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Total *</label>
                  <input
                    type="text"
                    name="rwTotal"
                    readOnly
                    value={rwTotal || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Services Total *</label>
                  <input
                    type="text"
                    name="servicesTotal"
                    readOnly
                    value={servicesTotal || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes (internal)</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Construction" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Verify Boundary and Topo and set site control *</label>
                  <input
                    type="text"
                    name="verify_boundary"
                    readOnly
                    value={this.state.quote.verify_boundary || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="verify_boundary_price"
                    readOnly
                    value={this.state.quote.verify_boundary_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Rough grade *</label>
                  <input
                    type="text"
                    name="rough_grade"
                    readOnly
                    value={this.state.quote.rough_grade || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="rough_grade_price"
                    readOnly
                    value={this.state.quote.rough_grade_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Stake building foundation *</label>
                  <input
                    type="text"
                    name="stake_building"
                    readOnly
                    value={this.state.quote.stake_building || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="stake_building_price"
                    readOnly
                    value={this.state.quote.stake_building_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Storm Sewer Staking *</label>
                  <input
                    type="text"
                    name="storm_sewer"
                    readOnly
                    value={this.state.quote.storm_sewer || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="storm_sewer_price"
                    readOnly
                    value={this.state.quote.storm_sewer_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Underground Utility Staking *</label>
                  <input
                    type="text"
                    name="underground_utility"
                    readOnly
                    value={this.state.quote.underground_utility || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="underground_utility_price"
                    readOnly
                    value={this.state.quote.underground_utility_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Stake Electric *</label>
                  <input
                    type="text"
                    name="stake_electric"
                    readOnly
                    value={this.state.quote.stake_electric || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="stake_electric_price"
                    readOnly
                    value={this.state.quote.stake_electric_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Stake concrete, curb and sidewalk *</label>
                  <input
                    type="text"
                    name="stake_concrete"
                    readOnly
                    value={this.state.quote.stake_concrete || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="stake_concrete_price"
                    readOnly
                    value={this.state.quote.stake_concrete_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Stake walls and fences *</label>
                  <input
                    type="text"
                    name="stake_walls"
                    readOnly
                    value={this.state.quote.stake_walls || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="stake_walls_price"
                    readOnly
                    value={this.state.quote.stake_walls_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Perform as-built survey for paving and G&D *</label>
                  <input
                    type="text"
                    name="perform_survey"
                    readOnly
                    value={this.state.quote.perform_survey || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="perform_survey_price"
                    readOnly
                    value={this.state.quote.perform_survey_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>CAD Tech *</label>
                  <input
                    type="text"
                    name="cad_tech"
                    readOnly
                    value={this.state.quote.cad_tech || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="cad_tech_price"
                    readOnly
                    value={this.state.quote.cad_tech_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Stake Subgrade *</label>
                  <input
                    type="text"
                    name="stake_subgrade"
                    readOnly
                    value={this.state.quote.stake_subgrade || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="stake_subgrade_price"
                    readOnly
                    value={this.state.quote.stake_subgrade_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Stake ABC *</label>
                  <input
                    type="text"
                    name="stake_abc"
                    readOnly
                    value={this.state.quote.stake_abc || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="stake_abc_price"
                    readOnly
                    value={this.state.quote.stake_abc_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Offsite Staking *</label>
                  <input
                    type="text"
                    name="offsite_staking"
                    readOnly
                    value={this.state.quote.offsite_staking || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="offsite_staking_price"
                    readOnly
                    value={this.state.quote.offsite_staking_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Additional Services *</label>
                  <input
                    type="text"
                    name="add_services"
                    readOnly
                    value={this.state.quote.add_services || "n/a"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Cost *</label>
                  <input
                    type="text"
                    name="add_services_price"
                    readOnly
                    value={this.state.quote.add_services_price || "0"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.quote.quote_type == "Flight" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Camera Option</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.camera_option || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>LiDAR Option</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_option || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Distance (miles)</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_distance || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>One Way/Round Trip</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.travel || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>On Line Hours Flight Time</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_time || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Fuel Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.fuel_charge || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>RAW Data Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.raw_data_charge || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Permit Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.permit_charge || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Airspace Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.airspace_charge || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          <p />
          {this.state.quote.quote_type == "NAAS" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Camera Option</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.camera_option || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>LiDAR Option</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.lidar_option || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Distance (miles)</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_distance || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>One Way/Round Trip</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.travel || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Distance Price)</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_distance_price}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_lines || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Lines Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_lines_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>On Line Hours Flight Time</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_time || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>On Line Hours Flight Time Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_time_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Data Drive</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.fuel_charge || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Data Drive Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.fuel_charge_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>RAW Data Process Level</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.raw_data_charge || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>RAW Data Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.raw_data_charge_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Permit Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.permit_charge || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Permit Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.permit_charge_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>OT Weekends/Holidays</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.nights_weekends || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>OT Weekends/Holidays Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.night_weekends_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>ABGPS Ground Station</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS Ground Station Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.travel_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>O2 Use as per High Altitude Number of Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.o2_use || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>O2 Use Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.o2_use_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Restricted or Class B Airspace Charge</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.airspace_charge || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Airspace Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.airspace_charge_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Overnight</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.overnight || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Overnight Price</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.overnight_price || "None"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Shipping</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.shipping || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost.toFixed(2) || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          <p />
          {this.state.quote.quote_type == "UAV" && (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <label>Quote Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.quote_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Flight</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Flight Sub</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.flight_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Flight Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Flight Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Flight Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.flight_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Flight Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.flight_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Survey</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Is Client Doing Survey? *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.client_survey || "No"}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>Panels</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.panels || "0"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>ABGPS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.abgps || ""}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Surveyor</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.quote.survey_sub || "None"}
                    className="form-control"
                  />
                </div>
                <div className="col">
                  <label>Survey Quote</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Survey Markup</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_markup || "0"}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col">
                  <label>Survey Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Survey Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.quote.survey_notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Mapping</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Exposures *</label>
                  <input
                    type="text"
                    name="img_qty"
                    readOnly
                    value={this.state.specification.exposures}
                    className="form-control"
                  />
                </div>
                <div style={{ display: "none" }} className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      name="img_cp"
                      readOnly
                      value={this.state.quote.img_cp}
                      className="form-control"
                    />
                  </div>
                </div>
                <div style={{ display: "none" }} className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={mappingTotal}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Obliques</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Exposures *</label>
                  <input
                    type="text"
                    name="xsec"
                    readOnly
                    value={this.state.quote.xsec}
                    className="form-control"
                  />
                </div>
                <div style={{ display: "none" }} className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      name="scan_cp"
                      readOnly
                      value={this.state.quote.scan_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div style={{ display: "none" }} className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={obliquesTotal}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Video</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Videos *</label>
                  <input
                    type="text"
                    name="contact_prints"
                    readOnly
                    value={this.state.specification.total_videos}
                    className="form-control"
                  />
                </div>
                <div style={{ display: "none" }} className="col">
                  <label>Cost Per</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      name="contact_prints_cp"
                      readOnly
                      value={this.state.quote.contact_prints_cp || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <div style={{ display: "none" }} className="col">
                  <label>Total *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={videoTotal}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <h4 className="mb-4 text-4xl">LiDAR</h4>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Flight Sub</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_flight_sub || "None"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Quote</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_quote || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Markup</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_markup || "0"}
                        className="form-control"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col">
                    <label>LiDAR Flight Cost</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={this.state.quote.lidar_flight_cost || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Size</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_size || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Size Type</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.size_type || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>L.O.D.</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.detail_level || "0"}
                      className="form-control"
                    />
                  </div>
                  {/*
                  <div className="col">
                    <label>Hrs</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_hours || "0"}
                      className="form-control"
                    />
                  </div>
                  */}
                  <div className="col">
                    <label>Total</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        readOnly
                        value={lidarProcessPrice || "0"}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>PPSM</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_ppsm || "0"}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Bare Earth Only</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_bare_earth || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>LiDAR Notes</label>
                    <textarea
                      type="text"
                      readOnly
                      value={this.state.quote.lidar_notes || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Cost</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.survey_quote_cost || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Total Adjustment</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={
                        this.state.quote.total_adjustment.toFixed(2) || "0"
                      }
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col">
                  <label>Explanation</label>
                  <textarea
                    readOnly
                    value={this.state.quote.adjustment_reason || "n/a"}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bonus Percentage</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.bonus_perc || "0"}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <h4 className="mb-4 text-4xl">Total Quote Cost</h4>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Total Quote Cost (includes $250 PM fee)</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      readOnly
                      value={this.state.quote.quote_cost || ""}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
            </div>
          )}
          {!this.state.specification.proposal_id && (
            <button
              type="button"
              onClick={this.toggleHidden.bind(this)}
              className="btn btn-secondary btn-sm"
            >
              {this.state.isHidden ? (
                <span>
                  Add Proposal{" "}
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span>
                  Close Proposal Form{" "}
                  <i className="fa fa-minus-circle" aria-hidden="true"></i>
                </span>
              )}
            </button>
          )}
          <p />
          {!this.state.isHidden && this.state.quote.quote_type == "UAV" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "Flight" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "NAAS" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "Film" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              planOnly={this.props.specification.map_type}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Film - Photo Geodetic" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                planOnly={this.props.specification.map_type}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {(!this.state.isHidden &&
            this.state.quote.quote_type == "Film (Image Only)") ||
            (!this.state.isHidden &&
              this.state.quote.quote_type ==
                "Film (Image Only) - Photo Geodetic" && (
                <ProposalForm
                  handleNewProposal={this.addNewProposal}
                  specId={this.state.quote.specification_id}
                  oppId={this.state.quote.opportunity_id}
                  quoteId={this.props.match.params.id}
                  proposalType={this.state.quote.quote_type}
                  userId={this.state.quote.user_id}
                  clientRep={this.props.contact.client_rep}
                />
              ))}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Film (Image Only)" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {(!this.state.isHidden &&
            this.state.quote.quote_type == "Film (Mapping Only)") ||
            (!this.state.isHidden &&
              this.state.quote.quote_type ==
                "Film (Mapping Only) - Photo Geodetic" && (
                <ProposalForm
                  handleNewProposal={this.addNewProposal}
                  specId={this.state.quote.specification_id}
                  oppId={this.state.quote.opportunity_id}
                  quoteId={this.props.match.params.id}
                  planOnly={this.props.specification.map_type}
                  proposalType={this.state.quote.quote_type}
                  userId={this.state.quote.user_id}
                  clientRep={this.props.contact.client_rep}
                />
              ))}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Film (Mapping Only)" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                planOnly={this.props.specification.map_type}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {!this.state.isHidden && this.state.quote.quote_type == "ADOT" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "Digital" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              planOnly={this.props.specification.map_type}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Digital - Photo Geodetic" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                planOnly={this.props.specification.map_type}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {(!this.state.isHidden &&
            this.state.quote.quote_type == "Digital (Image Only)") ||
            (!this.state.isHidden &&
              this.state.quote.quote_type ==
                "Digital (Image Only) - Photo Geodetic" && (
                <ProposalForm
                  handleNewProposal={this.addNewProposal}
                  specId={this.state.quote.specification_id}
                  oppId={this.state.quote.opportunity_id}
                  quoteId={this.props.match.params.id}
                  proposalType={this.state.quote.quote_type}
                  userId={this.state.quote.user_id}
                  clientRep={this.props.contact.client_rep}
                />
              ))}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Digital (Image Only)" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {(!this.state.isHidden &&
            this.state.quote.quote_type == "Digital (Mapping Only)") ||
            (!this.state.isHidden &&
              this.state.quote.quote_type ==
                "Digital (Mapping Only) - Photo Geodetic" && (
                <ProposalForm
                  handleNewProposal={this.addNewProposal}
                  specId={this.state.quote.specification_id}
                  oppId={this.state.quote.opportunity_id}
                  quoteId={this.props.match.params.id}
                  planOnly={this.props.specification.map_type}
                  proposalType={this.state.quote.quote_type}
                  userId={this.state.quote.user_id}
                  clientRep={this.props.contact.client_rep}
                />
              ))}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Digital (Mapping Only)" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                planOnly={this.props.specification.map_type}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {!this.state.isHidden && this.state.quote.quote_type == "LiDAR" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              planOnly={this.props.specification.map_type}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "LiDAR Processing" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                planOnly={this.props.specification.map_type}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {!this.state.isHidden && this.state.quote.quote_type == "Scan" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "Plot" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "Survey" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden &&
            this.state.quote.quote_type == "Construction" && (
              <ProposalForm
                handleNewProposal={this.addNewProposal}
                specId={this.state.quote.specification_id}
                oppId={this.state.quote.opportunity_id}
                quoteId={this.props.match.params.id}
                proposalType={this.state.quote.quote_type}
                userId={this.state.quote.user_id}
                clientRep={this.props.contact.client_rep}
              />
            )}
          {!this.state.isHidden && this.state.quote.quote_type == "BIM" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          {!this.state.isHidden && this.state.quote.quote_type == "Other" && (
            <ProposalForm
              handleNewProposal={this.addNewProposal}
              specId={this.state.quote.specification_id}
              oppId={this.state.quote.opportunity_id}
              quoteId={this.props.match.params.id}
              proposalType={this.state.quote.quote_type}
              userId={this.state.quote.user_id}
              clientRep={this.props.contact.client_rep}
            />
          )}
          <hr />
        </div>
      </div>
    );
  }
}
