import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hours",
    "rate",
    "total",
    "totalProductionCost",
    "surveyCost",
    "surveyMarkup",
    "surveyTotal",
    "lidarFlightCost",
    "lidarFlightMarkup",
    "lidarFlightTotal",
    "imageryFlightCost",
    "imageryFlightMarkup",
    "imageryFlightTotal",
    "uavFlightCost",
    "uavFlightMarkup",
    "uavFlightTotal",
    "expediteTotal",
    "adminTotal",
    "upchargePercent",
    "upchargeTotal",
    "projectTotal",
  ];

  connect() {
    this.updateTotals();
  }

  updateTotals() {
    let totalProductionCost = 0;

    // Calculate totals for production cost
    this.hoursTargets.forEach((element, index) => {
      const hours = parseFloat(element.value) || 0;
      const rate = parseFloat(this.rateTargets[index].value) || 0;
      const total = hours * rate;

      this.totalTargets[index].value = total.toFixed(2);
      totalProductionCost += total;
    });

    this.totalProductionCostTarget.value = totalProductionCost.toFixed(2);

    // Calculate survey total
    const surveyCost = parseFloat(this.surveyCostTarget.value) || 0;
    const surveyMarkup = parseFloat(this.surveyMarkupTarget.value) || 0;
    let surveyTotal = surveyCost + surveyCost * (surveyMarkup / 100);
    surveyTotal = Math.ceil(surveyTotal); // Round up
    this.surveyTotalTarget.value = surveyTotal;

    // Calculate lidar flight total
    const lidarFlightCost = parseFloat(this.lidarFlightCostTarget.value) || 0;
    const lidarFlightMarkup =
      parseFloat(this.lidarFlightMarkupTarget.value) || 0;
    let lidarFlightTotal =
      lidarFlightCost + lidarFlightCost * (lidarFlightMarkup / 100);
    lidarFlightTotal = Math.ceil(lidarFlightTotal); // Round up
    this.lidarFlightTotalTarget.value = lidarFlightTotal;

    // Calculate imagery flight total
    const imageryFlightCost =
      parseFloat(this.imageryFlightCostTarget.value) || 0;
    const imageryFlightMarkup =
      parseFloat(this.imageryFlightMarkupTarget.value) || 0;
    let imageryFlightTotal =
      imageryFlightCost + imageryFlightCost * (imageryFlightMarkup / 100);
    imageryFlightTotal = Math.ceil(imageryFlightTotal); // Round up
    this.imageryFlightTotalTarget.value = imageryFlightTotal;

    // Calculate UAV flight total
    const uavFlightCost = parseFloat(this.uavFlightCostTarget.value) || 0;
    const uavFlightMarkup = parseFloat(this.uavFlightMarkupTarget.value) || 0;
    let uavFlightTotal =
      uavFlightCost + uavFlightCost * (uavFlightMarkup / 100);
    uavFlightTotal = Math.ceil(uavFlightTotal); // Round up
    this.uavFlightTotalTarget.value = uavFlightTotal;

    // Calculate expedite total
    const expediteTotal = Math.ceil(
      parseFloat(this.expediteTotalTarget.value) || 0
    ); // Round up

    // Calculate initial project total
    const initialProjectTotal =
      totalProductionCost +
      surveyTotal +
      lidarFlightTotal +
      imageryFlightTotal +
      uavFlightTotal +
      expediteTotal;

    // Calculate adminTotal (2.5% of initialProjectTotal)
    let adminTotal = initialProjectTotal * 0.025;
    adminTotal = Math.ceil(adminTotal); // Round up
    this.adminTotalTarget.value = adminTotal;

    // Calculate upchargeTotal based on upchargePercent
    const upchargePercent = parseFloat(this.upchargePercentTarget.value) || 0;
    const upchargeTotal = Math.ceil(
      (totalProductionCost) * (upchargePercent / 100)
    );
    this.upchargeTotalTarget.value = upchargeTotal.toFixed(2);

    // Calculate final project total
    const finalProjectTotal = Math.ceil(
      initialProjectTotal + adminTotal + upchargeTotal
    ); // Round up
    this.projectTotalTarget.value = finalProjectTotal;
  }
}
